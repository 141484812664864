<template>
  <div class="vm-coverage-wrap" v-bind:class="{ 'has-address': searched }">
    <div class="coverage-results">
      <h2 v-if="searched">{{ coverageStatus }}</h2>
      <div class="coverage-form">
        <div class="row equal">
          <div class="col-sm-4 col-md-3">
            <div class="new-search-box">
              <v-text-field
                v-model="add"
                ref="autocomplete"
                id="map-search"
                label="Enter your address"
                placeholder=""
                required
              ></v-text-field>
              <div class="d-flex align-center search-again-row">
                <v-btn
                  class="search-btn"
                  href="#"
                  rounded
                  dark
                  @click="searchAddress($event)"
                >
                  Search again
                </v-btn>
                <span class="search-again"
                  >Or select your closest address below</span
                >
              </div>
            </div>
            <v-row align="center" id="filter-results-row">
              <v-col class="col-6 col-sm-12 col-md-6">
                <v-select
                  :items="addressList"
                  v-model="select"
                  name="suburb_filter"
                  item-text="suburb"
                  label="Filter by Suburb"
                  item-value="suburb"
                  @change="onFilter($event, 'suburb')"
                />
              </v-col>
              <v-col class="col-6 col-sm-12 col-md-6">
                <v-select
                  :items="addressList"
                  v-model="select"
                  name="streetname_filter"
                  item-text="street_name"
                  label="Filter by Street Name"
                  @change="onFilter($event, 'street_name')"
                />
              </v-col>
            </v-row>
            <v-row align="center" id="filter-results-row" v-if="isMdu">
              <v-col class="col-6 col-sm-12 col-md-6">
                <v-select
                  :items="addressList"
                  v-model="select"
                  name="filter_complex_estate_name"
                  item-text="mdu_name"
                  label="Filter by Complex or Estate"
                  item-value="mdu_name"
                  @change="onFilter($event, 'mdu_name')"
                />
              </v-col>
              <v-col class="col-6 col-sm-12 col-md-6" v-if="isMduSelected">
                <v-text-field
                  label="Unit Number"
                  placeholder="Enter your unit number"
                  @input="onFilterUnitNo"
                />
              </v-col>
            </v-row>
            <v-dialog v-if="isDialog" v-model="dialog2" max-width="290">
              <v-card>
                <v-card-text
                  class="text-center pink-primary"
                  style="padding: 16px !important;"
                >
                  <v-progress-circular
                    :size="70"
                    indeterminate
                    class="primary--text"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog v-model="isLoading" max-width="290">
              <v-card>
                <v-card-text
                  class="text-center pink-primary"
                  style="padding: 16px !important;"
                >
                  <v-progress-circular
                    :size="70"
                    indeterminate
                    class="primary--text"
                  />
                </v-card-text>
              </v-card>
            </v-dialog>
            <div class="filter-results ">
              <div id="content">
                <span>Please select your closest address</span>
                <ul class="g-address">
                  <br />
                  <li v-for="item in addressList" :key="item.message">
                    <a
                      class="address-options"
                      data-product-type="item.allowed_product_types"
                      data-address="new_string"
                      @click="clickAddress(item)"
                    >
                      <div v-if="item.mdu_unit_no == 'null'">
                        {{ item.street_number }} {{ item.street_name }},
                        {{ item.suburb }},
                        {{ item.city }} -
                        {{ Math.round(item.distance_to_orig) }}m
                      </div>
                      <div v-else>
                        {{ item.premises_id }}
                      </div>
                    </a>
                  </li>
                </ul>
                <span>Alternatively drop a pin on the map</span>
              </div>
            </div>
          </div>
          <div class="col-sm-8 col-md-9 pt-0">
            <div id="googlemap"></div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="490">
      <v-card>
        <v-card-title class="headline"
          >Use Google's location service?</v-card-title
        >
        <v-card-text
          >Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="goToProduct"
            >Product</v-btn
          >
          <v-btn color="green darken-1" text to="/">Change Address</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BusinessFeasibilityForm v-model="businessFeasibilityPopup" />
  </div>
</template>

<script>
  import gmapsInit from '@/utils/gmaps'
  import BusinessFeasibilityForm from '../../locations/BusinessFeasibilityForm'
  import { generateUuid } from '@/helpers'
  import $ from 'jquery'
  import STATUSES from '../../../constants/statuses'
  import PRODUCT_TYPES from '../../../constants/productTypes'
  import { mapState } from 'vuex'

  const VUE_APP_SEARCH_DB_URL = process.env.VUE_APP_SEARCH_DB_URL
  const VUE_APP_GEO_SERVER_URL = process.env.VUE_APP_GEO_SERVER_URL

  export default {
    name: 'HomeCoverage',
    components: {
      BusinessFeasibilityForm
    },
    data: () => ({
      suburb: '',
      street_name: '',
      street_number: '',
      country: '',
      province: '',
      city: '',
      searched: false,
      dialog: false,
      data: [],
      loading: false,
      markersArray: [],
      productType: 'VR',
      vumaMapType: '',
      addressList: [],
      globalAddressResponse: [],
      isMdu: false,
      isMduSelected: false,
      options: [],
      select: 0,
      coverageStatus: '',
      dialog2: false,
      add: $cookies.get('user_address'),
      tilesType: 'coverage_core',
      gMapsKey: 'AIzaSyB4xtQHKwZSauVBLxi716Ok41OFzmvKCvg',
      businessFeasibilityPopup: false,
      autocomplete: undefined,
      autocompleteOptions: {
        componentRestrictions: { country: 'za' },
        fields: ['formatted_address', 'geometry', 'address_components'],
        sessionToken: generateUuid()
      },
      premiseReference: ''
    }),
    async mounted() {
      // Remove previous click events
      $(document).off('click', '#order')
      $(document).on('click', '#order', event => {
        event.preventDefault()
        const product = sessionStorage.getItem('product')
        const willNavigateToShop = sessionStorage.getItem('willNavigateToShop')
        if (product === PRODUCT_TYPES.VUMACORE || product === PRODUCT_TYPES.VUMAKEY || (product === PRODUCT_TYPES.VUMAREACH && willNavigateToShop === 'true')) {
          window.location.href = '/omniscript'
          return
        }
        $('html, body').stop().animate({
          'scrollTop': $('.reach-shop').offset().top - 70,
        }, 900, 'swing')
      })

      this.productType = this.$route.name

      if (this.productType == 'VR') {
        this.tilesType = 'coverage_reach'
      }

      if (this.product === PRODUCT_TYPES.VUMAKEY) {
        this.tilesType = 'coverage_key'
      }

      try {
        let vue = this
        const google = await gmapsInit()

        this.geocoder = new google.maps.Geocoder()
        this.initializeAutocomplete()
        $(document).ready(function() {
          $(document).on('click', '.address-options', function() {})
        })

        let mapElement = document.getElementById('googlemap')

        if ($cookies.isKey('user_address')) {
          vue.searched = true

          let lat = parseFloat($cookies.get('lat'))
          let long = parseFloat($cookies.get('long'))
          let original_string = $cookies.get('user_address')

          let location_url = VUE_APP_SEARCH_DB_URL + '?original_str=' +
            $cookies.get('user_address') || undefined +
            '&street_number=' +
            $cookies.get('smi_street_number') || undefined +
            '&street_name=' +
            $cookies.get('smi_street_name') || undefined +
            '&suburb=' +
            $cookies.get('smi_suburb') || undefined +
            '&city_town=' +
            $cookies.get('smi_city') || undefined +
            '&lat=' +
            lat +
            '&long=' +
            long +
            ''
          this.dialog2 = true
          this.axios.get(location_url).then((response) => {
            let results = response.data.results
            let gresponse = results
            $.each(gresponse, function(key, value) {
              const tag = {
                suburb: value.suburb,
                code: value.street_name
              }
              this.options += tag
            })

            this.addressList = gresponse
            this.isMdu = this.hasMdu(gresponse)

            this.dialog2 = false

            const map = new google.maps.Map(mapElement, {
              center: {
                lat: lat,
                lng: long
              },
              zoom: 23,
              tilt: 0,
              rotateControl: false
            })

            var marker = new google.maps.Marker({
              position: {
                lat: lat,
                lng: long
              },
              draggable: true,
              map: map
            })

            this.markersArray.push(marker)
            let infowindow = new google.maps.InfoWindow()

            infowindow.setPosition(marker.getPosition())
            this.vumaMapType = vue.getOverlay(map, this.tilesType)
            map.setMapTypeId('roadmap')
            map.overlayMapTypes.push(this.vumaMapType)

            google.maps.event.addListener(map, 'zoom_changed', function() {
              map.overlayMapTypes.clear()
              if (map.getZoom() <= 17) {
                this.vumaMapType = vue.getOverlay(map, vue.tilesType)
                map.setMapTypeId('roadmap')
                map.overlayMapTypes.push(this.vumaMapType)
              } else if (map.getZoom() >= 18) {
                map.overlayMapTypes.clear()
                this.vumaMapType = vue.getOverlay(map, 'construction_premises')
                map.setMapTypeId('satellite')
                map.overlayMapTypes.push(this.vumaMapType)
              }
            })

            const { status, product, covered } = this.$store.state

            if (product === null) {
              return this.searchAddress();
            }

            const { popup, coverageStatus } = this.getPopUpContent(covered, status, product, original_string)

            this.coverageStatus = coverageStatus
            this.premiseReference = response?.data?.closest_result?.premise_reference_for_sp || ''
            $cookies.set('coverage_status', coverageStatus)
            $cookies.set('elastic_location_id', response?.data?.closest_result?.elastic_id || null)
            $cookies.set('premise_reference', this.premiseReference)

            infowindow.setContent(popup)

            infowindow.open(map)

            google.maps.event.addListener(map, 'click', function(e) {
              let geocoder = new google.maps.Geocoder()
              geocoder.geocode(
                {
                  latLng: e.latLng
                },
                function(results, status) {
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                      var pos = e.latLng
                      vue.lat = pos.lat()
                      vue.long = pos.lng()
                      vue.setAddressInCookies(results[0]?.address_components || null, results[0].formatted_address, vue.lat, vue.long)
                      vue.getProduct(
                        pos,
                        infowindow,
                        map,
                        results[0].formatted_address
                      )
                    } else {
                      this.address = 'empty'
                    }
                  } else {
                    this.address = this?.status
                  }
                }
              )
            })

            google.maps.event.addListener(marker, 'dragend', function(e) {
              let geocoder = new google.maps.Geocoder()
              geocoder.geocode(
                {
                  latLng: e.latLng
                },
                function(results, status) {
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                      var pos = e.latLng
                      vue.lat = pos.lat()
                      vue.long = pos.lng()
                      vue.setAddressInCookies(results[0]?.address_components || null, results[0].formatted_address, vue.lat, vue.long)
                      vue.getProduct(
                        pos,
                        infowindow,
                        map,
                        results[0].formatted_address
                      )
                    } else {
                      this.address = 'empty'
                    }
                  } else {
                    this.address = this?.status
                  }
                }
              )
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    computed: {
      ...mapState(['product']),
      isDialog() {
        return this.$store.getters.isDialog
      },
      isLoading() {
        return this.$store.getters.isLoading
      },
      willNavigateToShop () {
        return this.$store.state.willNavigateToShop
      },
    },
    watch: {
      months: function(newVal) {
        this.gotoMonth(newVal)
      },
      dialog2(val) {
        if (!val) return
        setTimeout(() => (this.dialog2 = false), 4000)
      }
    },
    created() {
      window.showBusinessFeasibilityDialog = () => {
        this.businessFeasibilityPopup = true
      }
    },
    methods: {
      clickAddress(value) {
        let vue = this

        this.premiseReference = value.premise_reference_for_sp || ''
        $cookies.set('elastic_location_id', value.elastic_id || null)
        $cookies.set('premise_reference', this.premiseReference)

        let mapElement = document.getElementById('googlemap')
        let address =
          ((this.isMdu && value.mdu_unit_no) || value.street_number) +
          ' ' +
          ((this.isMdu && value.mdu_name) || value.street_name) +
          ', ' +
          value.suburb +
          ', ' +
          value.city +
          ', ' +
          value.postal_code +
          ', ' +
          value.country
        var product_type = value.allowed_product_types

        switch (product_type) {
          case 'VC':
            vue.product = 'VC'
            break
          case 'VR':
            vue.product = 'VR'
            break
          case 'VK':
            vue.product = 'VK'
            break
          case 'VB':
            vue.product = 'VB'
            break
          default:
            vue.producthref = 'show-my-interest'
        }

        let infowindow = new google.maps.InfoWindow()
        this.dialog2 = true
        this.dialog2 = false

        let lat = parseFloat(value.latitude)
        let long = parseFloat(value.longitude)

        vue.lat = lat
        vue.long = long

        const map = new google.maps.Map(mapElement, {
          center: {
            lat: lat,
            lng: long
          },
          zoom: 23,
          tilt: 0,
          rotateControl: false
        })
        map.setMapTypeId('satellite')

        let pos = new google.maps.LatLng({
          lat: lat,
          lng: long
        })

        this.$store
          .dispatch('getCoverage', {
            latitude: lat,
            longitude: long,
            address: address
          })
          .then((coverage) => {
            const { popup, coverageStatus } = this.getPopUpContent(coverage.covered, coverage.status, coverage.product, address)
            $cookies.set('coverage_status', coverageStatus)
            vue.placeMarker(pos, map, infowindow, popup)
            this.vumaMapType = vue.getOverlay(map, vue.tilesType)
            map.overlayMapTypes.push(this.vumaMapType)
          })
      },
      buttonClicked: function() {
        this.$emit('button-clicked')
      },
      initializeAutocomplete() {
        try {
          const input = this.$refs.autocomplete.$refs.input
          this.autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOptions)

          this.autocomplete.addListener(
            'place_changed',
            this.handlePlaceChanged
          )
        } catch (error) {
          console.error(error)
        }
      },
      handlePlaceChanged () {
        this.autocompleteOptions = {
          ...this.autocompleteOptions,
          sessionToken: generateUuid()
        }
        this.autocomplete.setOptions(this.autocompleteOptions)
        const place = this.autocomplete.getPlace()
        this.lat = place.geometry.location.lat()
        this.long = place.geometry.location.lng()
        this.pos = place.formatted_address
        this.address_components = place.address_components
      },
      searchAddress(event) {
        event?.preventDefault()
        this.isMduSelected = false

        $cookies.set('user_address', $('#map-search').val())

        if ($('#map-search').val() === $cookies.get('user_address')) {
          this.dialog2 = true
          this.select = 0
        }

        let vue = this
        vue.loading = true
        this.dialog2 = true

        let url =
          'https://maps.googleapis.com/maps/api/geocode/json?address=' +
          $cookies.get('user_address') +
          '&key=' +
          vue.gMapsKey
        let infowindow = new google.maps.InfoWindow()
        this.axios.get(url).then((response) => {
          if (response.data.results.length != 0) {
            this.addressList = []
            let resutls = response.data.results[0]
            const address = response.data.results?.[0]?.formatted_address
            if (resutls.address_components[0].types[0] === 'street_number') {
              resutls.suburb = resutls.address_components[2].long_name
              resutls.street_name = resutls.address_components[1].long_name
              resutls.street_number = resutls.address_components[0].long_name
              resutls.country = resutls.address_components[6].long_name
              resutls.province = resutls.address_components[5].long_name
              resutls.city = resutls.address_components[3].long_name
            }
            if (
              resutls.address_components[0].types[0] === 'sublocality_level_1'
            ) {
              resutls.suburb = resutls.address_components[0].long_name
              resutls.street_name = null
              resutls.street_number = null
              resutls.country = resutls.address_components[4].long_name
              resutls.province = resutls.address_components[3].long_name
              resutls.city = resutls.address_components[1].long_name
            }
            if (resutls.address_components[0].types[0] === 'locality') {
              resutls.suburb = resutls.address_components[0].long_name
              resutls.street_name = null
              resutls.street_number = null
              resutls.country = resutls.address_components[3].long_name
              resutls.province = resutls.address_components[2].long_name
              resutls.city = resutls.address_components[1].long_name
            }

            let mapElement = document.getElementById('googlemap')

            let location_url = VUE_APP_SEARCH_DB_URL + '?original_str=' +
              $cookies.get('user_address') +
              '&street_number=' +
              resutls.street_number +
              '&country=' +
              resutls.country +
              '&street_name=' +
              resutls.street_name +
              '&suburb=' +
              resutls.suburb +
              '&province=' +
              resutls.province +
              '&city_town=' +
              resutls.city +
              '&lat=' +
              response.data.results[0].geometry.location.lat +
              '&long=' +
              response.data.results[0].geometry.location.lng +
              ''

            this.axios.get(location_url).then((response2) => {
              this.dialog2 = false
              let lat = parseFloat(
                response.data.results[0].geometry.location.lat
              )
              let long = parseFloat(
                response.data.results[0].geometry.location.lng
              )
              vue.lat = lat
              vue.long = long
              const map = new google.maps.Map(mapElement, {
                center: {
                  lat: lat,
                  lng: long
                },
                zoom: 23,
                tilt: 0,
                rotateControl: false
              })

              let pos = new google.maps.LatLng({
                lat: lat,
                lng: long
              })
              $cookies.set('lat', lat)
              $cookies.set('long', long)
              if (response2.data != '') {
                let results = response2.data.results
                let gresponse = results
                $.each(gresponse, function(key, value) {
                  const tag = {
                    suburb: value.suburb,
                    code: value.street_name
                  }
                  this.options += tag
                })

                this.addressList = gresponse
                this.isMdu = this.hasMdu(gresponse)
                this.premiseReference = response2?.data?.closest_result?.premise_reference_for_sp || ''
                $cookies.set('elastic_location_id', response2?.data?.closest_result?.elastic_id || null)
                $cookies.set('premise_reference', this.premiseReference)
              }

              this.$store
                .dispatch('getCoverage', {
                  latitude: lat,
                  longitude: long,
                  address: address
                })
                .then((coverage) => {
                  const currentPage = vue.$router.history.current.name
                  
                  if (coverage.covered) {
                    if (
                      coverage.product === PRODUCT_TYPES.VUMACORE &&
                      currentPage !== 'VC'
                    ) {
                      vue.$router.push({ name: 'VC' })
                    }

                    if (
                      (coverage.product === PRODUCT_TYPES.VUMAREACH || coverage.product === PRODUCT_TYPES.VUMAKEY) &&
                      currentPage !== 'VR'
                    ) {
                      vue.$router.push({ name: 'VR' })
                    }
                  }

                  const { popup, coverageStatus } = this.getPopUpContent(coverage.covered, coverage.status, coverage.product, address)

                  $cookies.set('coverage_status', coverageStatus)
                  this.coverageStatus = coverageStatus

                  vue.placeMarker(pos, map, infowindow, popup)
                  this.vumaMapType = vue.getOverlay(map, vue.tilesType)
                  map.overlayMapTypes.push(this.vumaMapType)

                  this.scrollBackToMap()
                })
            })
          }
        })
      },
      hasMdu(addressList) {
        if (!Array.isArray(addressList)) {
          return false
        }
        for (const address of addressList) {
          if (address.mdu_name !== 'null') {
            return true
          }
        }
        return false
      },
      onFilterUnitNo($event) {
        const unitRegex = new RegExp($event, 'i')
        this.addressList = this.globalAddressResponse.filter((address) =>
          unitRegex.test(address.mdu_unit_no)
        )
      },
      onFilter($event, filter_type) {
        let original_string = $cookies.get('user_address')
        let baseUrl = VUE_APP_SEARCH_DB_URL + '?original_str='
        if (filter_type === 'suburb') {
          this.url =
            baseUrl +
            original_string +
            '&lat=' +
            this.addressList[0].latitude +
            '&long=' +
            this.addressList[0].longitude +
            '&country=' +
            this.addressList[0].country +
            '&province=' +
            this.addressList[0].province +
            '&suburb=' +
            $event +
            '&street_name=' +
            this.addressList[0].street_name +
            '&street_number=' +
            this.addressList[0].street_number +
            '&filter_suburb=1'
        }
        if (filter_type === 'street_name') {
          this.url =
            baseUrl +
            original_string +
            '&lat=-26.284677&long=28.059514&street_name=' +
            $event +
            '&street_number=' +
            this.addressList[0].street_number +
            '&filter_street_name=1'
        }
        if (filter_type === 'mdu_name') {
          this.url =
            baseUrl +
            original_string +
            '&country=' +
            this.addressList[0].country +
            '&province=' +
            this.addressList[0].province +
            '&suburb=' +
            $event +
            '&street_name=' +
            this.addressList[0].street_name +
            '&street_number=' +
            this.addressList[0].street_number +
            '&complex_estate_name=' +
            $event +
            '&lat=' +
            this.addressList[0].latitude +
            '&long=' +
            this.addressList[0].longitude +
            '&filter_complex_estate_name=1'
          this.isMduSelected = true
        }
        this.dialog2 = true
        this.axios.get(this.url).then((response) => {
          this.dialog2 = false
          if (response.data != '') {
            this.coverageStatus = 'Your area is covered!'
            let results = response.data.results
            let gresponse = results
            this.premiseReference = response?.data?.closest_result?.premise_reference_for_sp || ''
            $cookies.set('elastic_location_id', response?.data?.closest_result?.elastic_id || null)
            $cookies.set('premise_reference', this.premiseReference)
            $.each(gresponse, function(key, value) {
              const tag = {
                suburb: value.suburb,
                code: value.street_name
              }
              this.options += tag
            })
            this.addressList = gresponse
            this.globalAddressResponse = gresponse
          } else {
            this.coverageStatus = 'Your area is not covered'
            let popup = `<div id="content"> <div class="g-condition">Fibre not available</div> <div class="g-address">' ${original_string} '</div> <a href="/show-my-interest" class="business-feasibility">Show My Interest</a></div> <br/>`
            this.clearMarkers(infowindow)
            this.placeMarker(pos, map, infowindow, popup)
          }
        })
      },
      goToProduct() {
        let redirectToProduct = this.data.product_types
        this.$router.push({
          name: redirectToProduct
        })
      },
      clearMarkers(infowindow) {
        infowindow?.close()
        if (this.markersArray) {
          for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null)
          }
        }
        this.markersArray = []
      },

      getProduct(pos, infowindow, map, new_string) {
        var latlng = {
          lat: parseFloat(pos.lat()),
          lng: parseFloat(pos.lng())
        }

        let location_url = VUE_APP_SEARCH_DB_URL + '?original_str=' +
          $cookies.get('user_address') || undefined +
          '&street_number=' +
          $cookies.get('street_number') || undefined +
          '&street_name=' +
          $cookies.get('street_name') || undefined +
          '&suburb=' +
          $cookies.get('suburb') || undefined +
          '&city_town=' +
          $cookies.get('city') || undefined +
          '&lat=' +
          pos.lat() +
          '&long=' +
          pos.lng()

        this.dialog2 = true
        this.axios.get(location_url).then((response) => {
          this.dialog2 = false
          const results = response.data.results

          if (results?.length) {
            this.addressList = results
          }
          this.premiseReference = response?.data?.closest_result?.premise_reference_for_sp || ''
          $cookies.set('elastic_location_id', response?.data?.closest_result?.elastic_id || null)
          $cookies.set('premise_reference', this.premiseReference)
        })

        this.$store
          .dispatch('getCoverage', {
            latitude: latlng.lat,
            longitude: latlng.lng,
            address: new_string
          })
          .then((coverage) => {
            const { popup, coverageStatus } = this.getPopUpContent(coverage.covered, coverage.status, coverage.product, new_string)
            $cookies.set('coverage_status', coverageStatus)
            this.coverageStatus = coverageStatus
            this.clearMarkers(infowindow)
            this.placeMarker(pos, map, infowindow, popup)
          })
      },

      getOverlay(map, type) {
        this.vumaMapType = new google.maps.ImageMapType({
          getTileUrl: function(coord, zoom) {
            var proj = map.getProjection()
            var zfactor = Math.pow(2, zoom)
            var top = proj.fromPointToLatLng(
              new google.maps.Point(
                (coord.x * 256) / zfactor,
                (coord.y * 256) / zfactor
              )
            )
            var bot = proj.fromPointToLatLng(
              new google.maps.Point(
                ((coord.x + 1) * 256) / zfactor,
                ((coord.y + 1) * 256) / zfactor
              )
            )

            var deltaX = 0.0
            var deltaY = 0.0

            var bbox =
              top.lng() +
              deltaX +
              ',' +
              (bot.lat() + deltaY) +
              ',' +
              (bot.lng() + deltaX) +
              ',' +
              (top.lat() + deltaY)

            var url = VUE_APP_GEO_SERVER_URL
            url += '&'
            url += '&SERVICE=WMS'
            url += '&VERSION=1.1.1'
            url += '&LAYERS=Vumatel:' + type
            url += '&FORMAT=image/png'
            url += '&TRANSPARENT=TRUE'
            url += '&SRS=EPSG:4326'
            url += '&BBOX=' + bbox
            url += '&STYLE=Vumatel'
            url += '&WIDTH=256'
            url += '&HEIGHT=256'
            return url
          },

          tileSize: new google.maps.Size(256, 256),
          radius: 1738000
        })

        return this.vumaMapType
      },
      placeMarker(position, map, infowindow, infoWindowContent = '') {
        let geocoder = new google.maps.Geocoder()
        let vue = this
        vue.loading = true
        let clickMarker = new google.maps.Marker({
          position: position,
          map: map,
          draggable: true
        })

        google.maps.event.addListener(clickMarker, 'dragend', function(e) {
          var pos = e.latLng
          vue.lat = pos.lat()
          vue.long = pos.lng()

          geocoder.geocode(
            {
              latLng: e.latLng
            },
            function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  var pos = e.latLng
                  vue.lat = pos.lat()
                  vue.long = pos.lng()
                  vue.setAddressInCookies(results[0]?.address_components || null, results[0].formatted_address, vue.lat, vue.long)
                  vue.getProduct(
                    pos,
                    infowindow,
                    map,
                    results[0].formatted_address
                  )
                } else {
                  this.address = 'empty'
                }
              } else {
                this.address = this?.status
              }
            }
          )
        })

        google.maps.event.addListener(map, 'zoom_changed', function() {
          map.overlayMapTypes.clear()
          if (map.getZoom() <= 17) {
            this.vumaMapType = vue.getOverlay(map, vue.tilesType)
            map.setMapTypeId('roadmap')
            map.overlayMapTypes.push(this.vumaMapType)
          } else if (map.getZoom() >= 18) {
            map.overlayMapTypes.clear()
            this.vumaMapType = vue.getOverlay(map, 'construction_premises')
            map.setMapTypeId('satellite')
            map.overlayMapTypes.push(this.vumaMapType)
          }
        })

        this.markersArray.push(clickMarker)

        infowindow.setPosition(clickMarker.getPosition())

        infowindow.setPosition(clickMarker.getPosition())
        var condition = 'Fibre is available at this address! Order now.'
        var popup = ''
        if (infoWindowContent != '') {
          popup = infoWindowContent
        } else {
          popup =
            '<div id="content"> <div class="g-condition">' +
            condition +
            '</div> <div class="g-address">' +
            vue.address +
            ' </div>'

          if (vue.productType == 'VR') {
            popup +=
              '<a href="#" class="button reach-products" id="order" >See available packages</a>'
          } else {
            popup +=
              '<a href="/omniscript" class="button" id="order">See available packages</a>'
          }
          popup += '</div>'
        }

        infowindow.setContent(popup)
        infowindow.open(map)
        map.panTo(clickMarker.getPosition())
        infowindow.open(map)
      },
      getPopUpContent(isCovered, coverageStatus, productType, address) {
        let coverageStatusTitle = 'Fibre not available',
          popup = `<div id="content"> <div class="g-condition">Fibre not available</div> <div class="g-address">${address}</div> <a href="/show-my-interest" class="business-feasibility">Show My Interest</a>`

        const isBuildStatus = coverageStatus === STATUSES.BUILD,
          isLiveStatus = coverageStatus === STATUSES.LIVE,
          isCoreProduct = productType === PRODUCT_TYPES.VUMACORE,
          isReachProduct = productType === PRODUCT_TYPES.VUMAREACH,
          isKeyProduct = productType === PRODUCT_TYPES.VUMAKEY

        if (isBuildStatus) coverageStatusTitle = 'Fibre is coming soon!'

        if (isBuildStatus && isCoreProduct) {
          popup = '<div id="content"><div class="g-condition">Fibre is coming soon!</div><a href="/omniscript" class="button" id="order">See available packages</a><div><a href="/faqs" class="button faq-btn">Frequently Asked Questions</a></div></div>'
        }

        if (isBuildStatus && (isReachProduct || isKeyProduct)) {
          popup = '<div id="content"><div class="g-condition">Fibre is coming soon!</div><a href="/show-my-interest" class="business-feasibility">Show My Interest</a><div><a href="/faqs" class="button faq-btn">Frequently Asked Questions</a></div></div>'
        }

        if (isLiveStatus) coverageStatusTitle = 'Your area is covered!'

        if (isLiveStatus && isCoreProduct) {
          popup = `<div id="content"> <div class="g-condition">Your area is fibre-ready. Place your order now.</div> <div class="g-address">${address}</div><a href="/omniscript" class="button" id="order">See available packages</a><div><a href="/faqs" class="button faq-btn">Frequently Asked Questions</a></div></div>`
        }

        if (isLiveStatus && (isReachProduct || isKeyProduct)) {
          popup = `<div id="content"> <div class="g-condition">Fibre is available at this address! Order now.</div> <div class="g-address">${address}</div><a href="#" class="button reach-products" id="order" >See available packages</a><div><a href="/faqs" class="button faq-btn">Frequently Asked Questions</a></div></div>`
        }

        return { coverageStatus: coverageStatusTitle, popup: popup }
      },
      setAddressInCookies(addressComponents, addressString, lat, long) {
        if (!addressComponents) return

        const streetNumber = addressComponents.find(e => e.types.includes('street_number'))?.long_name || ''
        const streetName = addressComponents.find(e => e.types.includes('route'))?.long_name || ''
        const suburb = addressComponents.find(e => e.types.includes('sublocality'))?.long_name || ''
        const city = addressComponents.find(e => e.types.includes('locality'))?.long_name || ''

        $cookies.set('user_address', addressString)
        $cookies.set('smi_street_number', streetNumber)
        $cookies.set('smi_street_name', streetName)
        $cookies.set('smi_suburb', suburb)
        $cookies.set('smi_city', city)
        $cookies.set('lat', lat)
        $cookies.set('long', long)
      },
      scrollBackToMap() {
        // Painful way to scroll back to map
        $('html, body')
          .stop()
          .animate(
            {
              scrollTop: $('.vm-coverage-wrap').offset().top - 70
            },
            500,
            'swing'
          )
      }
    }
  }
</script>

<style lang="scss">
  .vm-coverage-wrap {
    height: 0;
    overflow: hidden;

    &.has-address {
      height: auto;
      overflow-y: auto;
    }
  }

  .filter-results {
    ul.g-address {
      max-height: 370px;
      overflow: auto;
    }
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-size: 12px;
  }

  .theme--light.v-input input#searchbar {
    font-size: 20px !important;
  }

  .pink-primary .primary--text {
    color: #ee008c !important;
    caret-color: #ee008c !important;
  }

  fieldset[disabled] .multiselect {
    pointer-events: none;
  }

  .multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block;
  }

  .multiselect__spinner:after,
  .multiselect__spinner:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: #41b883 transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
  }

  .multiselect__spinner:before {
    animation: a 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
  }

  .multiselect__spinner:after {
    animation: a 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
  }

  .multiselect__loading-enter-active,
  .multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
  }

  .multiselect__loading-enter,
  .multiselect__loading-leave-active {
    opacity: 0;
  }

  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
  }

  .multiselect * {
    box-sizing: border-box;
  }

  .multiselect:focus {
    outline: none;
  }

  .multiselect--disabled {
    opacity: 0.6;
  }

  .multiselect--active {
    z-index: 1;
  }

  .multiselect--active:not(.multiselect--above) .multiselect__current,
  .multiselect--active:not(.multiselect--above) .multiselect__input,
  .multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .multiselect--active .multiselect__select {
    transform: rotate(180deg);
  }

  .multiselect--above.multiselect--active .multiselect__current,
  .multiselect--above.multiselect--active .multiselect__input,
  .multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .multiselect__input,
  .multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border 0.1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
  }

  .multiselect__input::-webkit-input-placeholder {
    color: #35495e;
  }

  .multiselect__input:-ms-input-placeholder {
    color: #35495e;
  }

  .multiselect__input::placeholder {
    color: #35495e;
  }

  .multiselect__tag ~ .multiselect__input,
  .multiselect__tag ~ .multiselect__single {
    width: auto;
  }

  .multiselect__input:hover,
  .multiselect__single:hover {
    border-color: #cfcfcf;
  }

  .multiselect__input:focus,
  .multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none;
  }

  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
  }

  .multiselect__tags-wrap {
    display: inline;
  }

  .multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
  }

  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #41b883;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }

  .multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
  }

  .multiselect__tag-icon:after {
    content: '\D7';
    color: #266d4d;
    font-size: 14px;
  }

  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #369a6e;
  }

  .multiselect__tag-icon:focus:after,
  .multiselect__tag-icon:hover:after {
    color: #fff;
  }

  .multiselect__current {
    min-height: 40px;
    overflow: hidden;
    padding: 8px 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
  }

  .multiselect__current,
  .multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
  }

  .multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform 0.2s ease;
  }

  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: '';
  }

  .multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
  }

  .multiselect--active .multiselect__placeholder {
    display: none;
  }

  .multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  .multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
  }

  .multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
  }

  .multiselect__content::webkit-scrollbar {
    display: none;
  }

  .multiselect__element {
    display: block;
  }

  .multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
  }

  .multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
  }

  .multiselect__option--highlight {
    background: #41b883;
    outline: none;
    color: #fff;
  }

  .multiselect__option--highlight:after {
    content: attr(data-select);
    background: #41b883;
    color: #fff;
  }

  .multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700;
  }

  .multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }

  .multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect--disabled {
    background: #ededed;
    pointer-events: none;
  }

  .multiselect--disabled .multiselect__current,
  .multiselect--disabled .multiselect__select,
  .multiselect__option--disabled {
    background: #ededed;
    color: #a6a6a6;
  }

  .multiselect__option--disabled {
    cursor: text;
    pointer-events: none;
  }

  .multiselect__option--group {
    background: #ededed;
    color: #35495e;
  }

  .multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff;
  }

  .multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e;
  }

  .multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede;
  }

  .multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
  }

  .multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
  }

  .multiselect-enter-active,
  .multiselect-leave-active {
    transition: all 0.15s ease;
  }

  .multiselect-enter,
  .multiselect-leave-active {
    opacity: 0;
  }

  .multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
  }

  [dir='rtl'] .multiselect {
    text-align: right;
  }

  [dir='rtl'] .multiselect__select {
    right: auto;
    left: 1px;
  }

  [dir='rtl'] .multiselect__tags {
    padding: 8px 8px 0 40px;
  }

  [dir='rtl'] .multiselect__content {
    text-align: right;
  }

  [dir='rtl'] .multiselect__option:after {
    right: auto;
    left: 0;
  }

  [dir='rtl'] .multiselect__clear {
    right: auto;
    left: 12px;
  }

  [dir='rtl'] .multiselect__spinner {
    right: auto;
    left: 1px;
  }

  @keyframes a {
    0% {
      transform: rotate(0);
    }
    to {
      transform: rotate(2turn);
    }
  }

  #googlemap {
    position: relative;
    width: 100%;
    min-height: 75vh;
    height: 100%;
    #content {
      padding: 20px 20px 0 20px;
    }
  }

  .coverage-results {
    position: relative;
    text-align: center;
    h2 {
      background: #ff008e;
      color: #fff;
      padding: 3rem 1rem;
    }

    .floating-action {
      position: absolute;
      left: 50%;
      bottom: 3rem;
      transform: translateX(-50%);
    }

    .coverage-form {
      padding-left: 24px;
      .new-search-box {
        padding-top: 1rem;
        margin-bottom: 1rem;
        text-align: left;
        .search-again {
          display: inline-block;
          margin-left: 0.5rem;
          font-size: 16px;
        }
      }

      .v-text-field {
        .v-label--active {
          font-size: 20px;
          height: auto;
          line-height: initial;
          top: 2px;
          color: #000;
        }

        input {
          font-size: 18px;
          max-height: initial;
          line-height: initial;
        }
      }

      #filter-results-row {
        .v-text-field {
          .v-label--active {
            top: -6px;
            color: rgba(0, 0, 0, 0.6) !important;
          }
        }
      }
    }
  }

  #content {
    font-style: normal;
    font-size: 20px;
    padding: 0;
    text-align: center;
    .g-condition {
      font-weight: bold;
      margin-bottom: 1rem;
      font-family: 'Core Sans R 35';
    }

    .g-address {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 1rem;
      padding-left: 0;
      font-family: 'Core Sans R 35';
    }

    #order {
      padding: 0.75rem 46px;
      border-radius: 31px;
      color: #fff;
      background-color: #ff008e;
      border: 0;
      font-weight: 600;
      display: inline-block;
      text-decoration: none;
    }

    ul {
      li {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 5px;
        a {
          display: inline-block;
          font-size: 13px;
          padding: 6px 14px;
          display: inline-block;
          border: 1px solid #ff008e;
          border-radius: 20px;
          font-weight: 600;
          transition: 0.4s all;
          color: #000;

          &:hover {
            color: #ffffff;
            background-color: #ff008e;
          }
        }
      }
    }
  }

  .business-feasibility {
    font-family: 'Core Sans R 55';
    margin: 0.5rem;
    padding: 1rem 0.6rem;
    border-radius: 2rem;
    color: #fff !important;
    background-color: #EE008C;
    border: none !important;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
    width: 260px;
    max-width: 3444px;
    font-size: 1.1rem;
    outline: none !important;
  }

  @media screen and (max-width: 767px) {
    .coverage-results .coverage-form .new-search-box .v-text-field .v-label {
      top: 0;
    }
    .coverage-results .coverage-form #content {
      font-size: 14px;
    }
    .business-feasibility {
      font-size: 0.9rem;
      width: 200px;
    }
    #filter-results-row {
      margin-bottom: 1rem;
    }
    #filter-results-row .v-text-field {
      margin-top: 0;
    }
    #filter-results-row .col-12 {
      padding-top: 0;
      padding-bottom: 0;
    }
    #filter-results-row .v-input__slot {
      margin-bottom: 0;
    }
    .filter-results ul.g-address {
      max-height: 270px;
      padding-left: 0;
    }
    .search-again-row.d-flex {
      display: block !important;
      .search-again {
        display: block !important;
        margin-left: 0 !important;
        margin-top: 1rem;
      }
    }
  }

  .faq-btn {
    padding: 1rem;
    color: #ff008e !important;
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
  }

  @media screen and (max-width: 590px) {
    .filter-results ul.g-address {
      max-height: 230px;
    }
    .coverage-results .coverage-form .filter-results,
    .coverage-results .coverage-form .new-search-box,
    #filter-results-row {
      padding-left: 25px;
      padding-right: 25px;
    }
    .coverage-results .coverage-form {
      padding-left: 0;
    }
    .business-feasibility {
      width: 190px;
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 425px) {
    .coverage-results #googlemap {
      height: 350px;
    }
  }
</style>
